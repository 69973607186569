import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Flex, Heading } from "theme-ui"

import { removeDuplicates } from "../helpers"
import Container from "../components/Container"
import TagCard from "../components/TagCard"

const Authors = ({ location, data }) => {
  const {
    allBlog: { nodes },
  } = data

  const tags = nodes.map(({ tags }) => tags).flat()

  return (
    <Layout location={location} title={"Newsroom Authors"}>
      <Seo
        title="Newsroom Authors"
        description="View all the different authors @ GoNation Newsroom!"
      ></Seo>
      <Box
        sx={{ padding: 3, minHeight: "100vh", pt: [3, 4, 4, 5] }}
        as="section"
      >
        <Container width={900}>
          <Heading
            as="h1"
            sx={{
              mb: 4,
              color: "text",
              fontSize: [5, 5, 5, 6, 6],
              fontWeight: 400,
              borderBottom: "3px solid",
              borderColor: "primary",
            }}
          >
            Browse Articles By Tags
          </Heading>
          <Flex sx={{ flexWrap: "wrap" }}>
            {removeDuplicates(tags).map(tag => (
              <Box key={tag} sx={{ mr: 3, mb: 3 }}>
                <TagCard tag={tag} />
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
    </Layout>
  )
}

export default Authors

export const tags = graphql`
  query {
    allBlog {
      nodes {
        tags
      }
    }
  }
`
