import React from "react"
import { Link } from "gatsby"
import { Box, Text } from "theme-ui"
import { slugifyLower } from "../helpers"

const TagCard = ({ tag }) => {
  return (
    <Link to={slugifyLower(tag)}>
      <Box
        sx={{
          color: "inherit",
          textDecoration: "none!important",
          paddingY: 3,
          paddingX: 4,
          backgroundColor: "isWhite",
          textTransform: "capitalize",
          fontWeight: 600,
          borderLeft: "6px solid",
          borderColor: "primary",
          cursor: "pointer",
          transition: "all .3s",
          "&:hover": {
            boxShadow: "0 0 12px rgba(0,0,0,.3)",
            transition: "all .3s",
          },
        }}
      >
        <Text as="p">{tag}</Text>
      </Box>
    </Link>
  )
}

export default TagCard
